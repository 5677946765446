@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "quantico";
    src: url("Fonts/Quantico-Regular.ttf");
    font-weight:normal;
  }

  @font-face {
    font-family: "quantico";
    src: url("Fonts/Quantico-Bold.ttf");
    font-weight:bold;
  }


h1, h2, h3, h4, h5 {
  font-family: "quantico";
  font-weight: bold;
}


